export const storageKeys = {
  DATA_SCRAMBLER: 'devToolsDataScramblerEnabled',
  REDUX_DEV_TOOLS: 'devToolsReduxDevToolsEnabled',
  LOGS_DISABLED: 'devToolsLogsDisabled',
  THEME: 'devToolsLocalTheme',
  THEME_MODE: 'devToolsLocalThemeMode',
  LOCAL_PERMISSIONS: 'devToolsLocalPermissions',
  DEBUG: 'devToolsDebug',
  DEBUG_STRICT_MODE: 'devToolsDebugStrictMode',
  DEBUG_PROFILING: 'devToolsDebugProfiling',
};
