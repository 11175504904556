import {
  authenticationReducer,
  queriesReducer,
  errorReporterReducer,
  configReducer,
  statusReducer,
  cloudfrontReducer,
  userSettingsReducer,
  actionStatusReducer,
  errorPopupReducer,
  emergencyReducer,
} from '@customer-connect/redux-reducers';

import { dynamicModulesReducer } from '@customer-connect/redux-reducers';

const reducers = {
  auth: authenticationReducer,
  queries: queriesReducer,
  errorReporter: errorReporterReducer,
  config: configReducer,
  status: statusReducer,
  cloudfront: cloudfrontReducer,
  userSettings: userSettingsReducer,
  actionStatus: actionStatusReducer,
  errorPopup: errorPopupReducer,
  dynamicModules: dynamicModulesReducer,
  emergency: emergencyReducer,
};

export default reducers;
