import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage';
import { selectAllPermissions } from '@customer-connect/redux-selectors';
import { useDebugSettings } from '@customer-connect/development-debug-utils';
import { storageKeys } from '~/hooks/useDevTools/constants';
import {
  DEVELOPER_PERMISSION,
  DEVELOPER_LOCAL_PERMISSIONS_PERMISSION,
  DEVELOPER_SCRAMBLER_PERMISSION,
  DEVELOPER_THEME_PERMISSION,
  DEVELOPER_DEBUG_PERMISSION,
} from '~/permissions';

const { DATA_SCRAMBLER, REDUX_DEV_TOOLS, LOGS_DISABLED, THEME, LOCAL_PERMISSIONS, THEME_MODE } = storageKeys;

const useDevTools = () => {
  const { debugModeEnabled, toggleDebugMode } = useDebugSettings();
  const permissions = useSelector(selectAllPermissions);
  const scramblerPermission = useMemo(() => permissions?.includes(DEVELOPER_SCRAMBLER_PERMISSION) || false, [permissions]);
  const themeSwitcherPermission = useMemo(() => permissions?.includes(DEVELOPER_THEME_PERMISSION) || false, [permissions]);
  const localPermissionsPermission = useMemo(() => permissions?.includes(DEVELOPER_LOCAL_PERMISSIONS_PERMISSION) || false, [permissions]);
  const devOverlayPermission = useMemo(() => permissions?.includes(DEVELOPER_PERMISSION) || false, [permissions]);
  const debugPermission = useMemo(() => permissions?.includes(DEVELOPER_DEBUG_PERMISSION) || false, [permissions]);
  const [dataScrambleEnabled, setDataScrambleEnabled] = useLocalStorage(DATA_SCRAMBLER, false);
  const [reduxDevToolsEnabled, setReduxDevToolsEnabled] = useLocalStorage(REDUX_DEV_TOOLS, false);
  const [logsDisabled, setLogsDisabled] = useLocalStorage(LOGS_DISABLED, false);
  const [devToolsTheme, setTheme] = useLocalStorage(THEME);
  const [devToolsThemeMode, setThemeMode] = useLocalStorage(THEME_MODE);
  const [localPermissions, setLocalPermissions] = useLocalStorage(LOCAL_PERMISSIONS, [
    { id: 'this.permission.is.enabled', enabled: true },
    { id: 'this.permission.is.disabled', enabled: false },
  ]);

  const toggleDataScramble = useCallback(() => {
    if (scramblerPermission) {
      setDataScrambleEnabled(!dataScrambleEnabled);
      window.location.reload();
    }
  }, [dataScrambleEnabled, setDataScrambleEnabled, scramblerPermission]);

  const disableDataScramble = useCallback(() => {
    deleteFromStorage(DATA_SCRAMBLER);
  }, []);

  const toggleReduxDevTools = useCallback(() => {
    setReduxDevToolsEnabled(!reduxDevToolsEnabled);
    window.location.reload();
  }, [reduxDevToolsEnabled, setReduxDevToolsEnabled]);

  const toggleLogsDisabled = useCallback(() => {
    setLogsDisabled(!logsDisabled);
    window.location.reload();
  }, [logsDisabled, setLogsDisabled]);

  const setDevToolsTheme = useCallback(
    (theme) => {
      if (theme) {
        setTheme(theme);
      } else {
        deleteFromStorage(THEME);
      }
    },
    [devToolsTheme, setTheme],
  );

  const setDevToolsThemeMode = useCallback(
    (darkModeOn) => {
      console.log('darkModeOn', darkModeOn);
      if (darkModeOn) {
        setThemeMode('dark');
      } else {
        deleteFromStorage(THEME_MODE);
      }
    },
    [devToolsThemeMode, setThemeMode],
  );

  return {
    dataScrambleEnabled,
    reduxDevToolsEnabled,
    logsDisabled,
    devToolsTheme,
    devToolsThemeMode,
    scramblerPermission,
    toggleReduxDevTools,
    toggleLogsDisabled,
    toggleDataScramble,
    disableDataScramble,
    setDevToolsTheme,
    setDevToolsThemeMode,
    devOverlayPermission,
    localPermissionsPermission,
    themeSwitcherPermission,
    localPermissions,
    setLocalPermissions,
    debugPermission,
    debugModeEnabled,
    toggleDebugMode,
  };
};

export default useDevTools;
