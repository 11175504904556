import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOnline } from '@customer-connect/redux-actions';

const OnlineChecker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.debug('App Shell', 'Online', 'Setting initial status', navigator.onLine);
    dispatch(setOnline({ value: navigator.onLine }));

    window.addEventListener('online', () => {
      console.debug('App Shell', 'Online', 'Online status changed to', true);
      dispatch(setOnline({ value: true }));
    });

    window.addEventListener('offline', () => {
      console.debug('App Shell', 'Online', 'Online status changed to', false);
      dispatch(setOnline({ value: false }));
    });
  }, []);

  return null;
};

export default OnlineChecker;
