import config from '@customer-connect/config-legacy';

export const disableGA = () => {
  config.set('disableGA', true);
  window.localStorage.setItem('disableGA', true);
};
export const enableGA = () => {
  config.set('disableGA', false);
  window.localStorage.removeItem('disableGA');
};
