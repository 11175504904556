import { createSelector } from 'reselect';
import { takeLatest, select, put, take, call } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import deepmerge from 'deepmerge';
import { produce } from 'immer';
import { saveSessionSettings } from '@customer-connect/requests-legacy';
import { selectSessionSettings } from '@customer-connect/redux-selectors/dist/userSettings';
import { getSessionSetting } from '@customer-connect/redux-actions';
import config from '@customer-connect/config-legacy';
import { enableGA, disableGA } from './GAHelper';

export const STATE_NAME = 'cookieConsent';
export const APPROVE_COOKIE = `core/${STATE_NAME}/APPROVE_COOKIE`;
export const LOAD_COOKIE_ACCEPTANCE = `core/${STATE_NAME}/LOAD_COOKIE_ACCEPTANCE`;
export const CHANGE_COOKIE_CONSENT = `core/${STATE_NAME}/CHANGE_COOKIE_CONSENT`;

// action
export const approveCookie = (data) => ({
  type: APPROVE_COOKIE,
  payload: data,
});

export const changeCookieConsent = (data) => ({
  type: CHANGE_COOKIE_CONSENT,
  payload: data,
});

export const loadCookieAccept = () => ({
  type: LOAD_COOKIE_ACCEPTANCE,
});

// Selectors
export const selectCookie = (state) => state[STATE_NAME];

export const selectCookieStatus = createSelector(selectSessionSettings, (sessionSettings) => {
  /* UUUUUGLY temp solution for DOVE, well we will remove cookie functionality anyhow */
  if (config.getBrand() === 'snacdealeraccess') {
    const sessionSetting = getSessionSetting(sessionSettings, 'COOKIE_CONSENT_DOVE');
    return (sessionSetting && JSON.parse(sessionSetting)) || null;
  }
  const sessionSetting = getSessionSetting(sessionSettings, 'COOKIE_CONSENT');
  return (sessionSetting && JSON.parse(sessionSetting)) || null;
});

const getApprovedCookiesInner = createSelector(selectCookie, (cookie) => cookie.approvedCookies);
export const getApprovedCookies = () => getApprovedCookiesInner;

const getApprovedInner = createSelector(selectCookie, (cookie) => cookie.approved);
export const getApproved = () => getApprovedInner;

const getLoadingInner = createSelector(selectCookie, (cookie) => cookie.loading);
export const getLoading = () => getLoadingInner;

// reducer
const initialState = {
  approvedCookies: {
    necessary: true,
  },
  loading: true,
  approved: false,
};

export default produce((draft, action = { type: null }) => {
  switch (action.type) {
    case APPROVE_COOKIE:
      draft.approvedCookies = deepmerge(draft.approvedCookies, action.payload);
      draft.approved = true;
      break;
    case LOAD_COOKIE_ACCEPTANCE:
      draft.loading = false;
      break;
    case CHANGE_COOKIE_CONSENT:
      draft.approvedCookies[action.payload] = !get(draft, ['approvedCookies', action.payload]);
      draft.approved = true;
      break;
    default:
  }
}, initialState);

const storedDatePlusOneYear = (storedDate) => {
  const storedYear = new Date(storedDate);
  storedYear.setFullYear(storedYear.getFullYear() + 1);
  return storedYear;
};

export function* checkIfCookieIsAccepted() {
  const userHasAcceptedCookies = yield select(selectCookieStatus);

  if (userHasAcceptedCookies && new Date() <= storedDatePlusOneYear(userHasAcceptedCookies.date)) {
    yield put(approveCookie({ ...userHasAcceptedCookies.approved }));

    if (!userHasAcceptedCookies.approved.statistics) {
      disableGA();
    } else {
      enableGA();
    }
  } else {
    disableGA();
  }
}

export const createCookie = (cookieConsents) => ({
  approved: { ...cookieConsents },
  date: new Date(),
});

export function* saveCookieToUser() {
  const cookieConsents = yield select(getApprovedCookies());
  const userHasAcceptedCookies = yield select(selectCookieStatus);

  if (!cookieConsents?.approved?.statistics) {
    disableGA();
  } else {
    enableGA();
  }

  // save to session if differs from approvedCookie state
  if (
    !userHasAcceptedCookies ||
    !isEqual({ ...userHasAcceptedCookies.approved }, { ...cookieConsents }) ||
    new Date() >= storedDatePlusOneYear(userHasAcceptedCookies.date)
  ) {
    const cookiesToUpload = yield call(createCookie, cookieConsents);
    if (config.getBrand() === 'snacdealeraccess') {
      yield put(
        saveSessionSettings({
          key: 'COOKIE_CONSENT_DOVE',
          value: JSON.stringify({ ...cookiesToUpload }),
          scope: 'GLOBAL',
        }),
      );
    } else {
      yield put(
        saveSessionSettings({
          key: 'COOKIE_CONSENT',
          value: JSON.stringify({ ...cookiesToUpload }),
          scope: 'GLOBAL',
        }),
      );
    }
  }
}

export function* cookieConsentSagas() {
  yield take(LOAD_COOKIE_ACCEPTANCE);
  yield call(checkIfCookieIsAccepted);
  yield takeLatest(APPROVE_COOKIE, saveCookieToUser);
  yield takeLatest(CHANGE_COOKIE_CONSENT, saveCookieToUser);
}
