import { useMemo } from 'react';
import { useTitle } from 'react-use';
import { useFormattedBrandName } from '@customer-connect/hooks-web';

export const useDocumentTitle = (title) => {
  const brandName = useFormattedBrandName();
  const documentTitle = useMemo(() => (title ? `${title} | ${brandName}` : brandName), [title, brandName]);
  useTitle(documentTitle);
};

export default useDocumentTitle;
