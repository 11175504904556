import { useSessionSetting, useHasEveryPermission } from '@customer-connect/hooks-web';
import { ACTIVATION_PERMISSIONS, SESSION_KEY_PORTAL_INITIAL_SETUP_DONE } from '~/hooks/useInitialSetup/constants';

const useInitialSetup = () => {
  const hasPermissions = useHasEveryPermission(ACTIVATION_PERMISSIONS);
  const initialSetupDone = useSessionSetting(SESSION_KEY_PORTAL_INITIAL_SETUP_DONE);
  return hasPermissions && !initialSetupDone;
};

export default useInitialSetup;
