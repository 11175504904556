import { array, object } from 'prop-types';
import { ThemeProvider } from '@ccsdk/kingbolt';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorBoundary from '@customer-connect/components-legacy/dist/components/ErrorBoundary';
import OnlineChecker from '~/components/OnlineChecker';
import PageStatusChecker from '~/components/PageStatusChecker';
import ReduxProvider from '~/components/ReduxProvider';
import App from '~/components/App';
import { wrapCreateBrowserRouter, withProfiler } from '@sentry/react';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([{ path: '*', element: <App /> }]);

const Root = ({ theme, config, queries, dynamicModules, cloudfrontMeta }) => {
  return (
    <ReduxProvider cloudfrontMeta={cloudfrontMeta} config={config} dynamicModules={dynamicModules} queries={queries}>
      <OnlineChecker />
      <PageStatusChecker />
      <ThemeProvider theme={theme}>
        <ErrorBoundary.Root>
          <RouterProvider router={router} />
        </ErrorBoundary.Root>
      </ThemeProvider>
    </ReduxProvider>
  );
};

Root.propTypes = {
  cloudfrontMeta: object,
  config: object,
  dynamicModules: array,
  queries: object,
  theme: object,
};

export default withProfiler(Root, { name: 'Root' });
