import { call, put } from 'redux-saga/effects';
import { userAuthorized } from '@customer-connect/redux-actions';

export function* renewToken({ skipTokenCheck = false } = {}) {
  /* eslint-disable-next-line no-undef */
  console.debug('App Shell', 'Auth', 'Renewing token', skipTokenCheck);
  const tokenData = yield call(window.ccauth.renewToken, { skipTokenCheck });
  console.debug('App Shell', 'Auth', 'Renewed Token');
  yield put(userAuthorized(tokenData));
}
