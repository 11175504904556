import { defineMessages } from 'react-intl';

export default defineMessages({
  incompatibleBrowserMessage: {
    id: 'core.incompatibleBrowser',
    description: 'Message to show when the users browser is incompatible with ccsdk',
    defaultMessage:
      'Your browser is not compatible with this version of the Customer Connect framework (CCSDK). We recommend that you install the latest version of Google Chrome, Microsoft Edge, Apple Safari, Mozilla Firefox or another modern browser. Please contact your IT support with questions about how to upgrade the browser on your workstation.',
  },
  commonInfo: {
    id: 'core.common.info',
    description: 'Common string',
    defaultMessage: 'info',
  },
  commonConfirm: {
    id: 'core.common.confirm',
    description: 'Common string',
    defaultMessage: 'confirm',
  },
  commonWarning: {
    id: 'core.common.warning',
    description: 'Common string',
    defaultMessage: 'warning',
  },
  commonOk: {
    id: 'core.common.ok',
    description: 'Common string',
    defaultMessage: 'ok',
  },
  commonCancel: {
    id: 'core.common.cancel',
    description: 'Common string',
    defaultMessage: 'cancel',
  },
  commonClose: {
    id: 'core.common.close',
    description: 'Common string',
    defaultMessage: 'close',
  },
  commonSave: {
    id: 'core.common.save',
    description: 'Common string',
    defaultMessage: 'save',
  },
  commonPrice: {
    id: 'core.common.price',
    description: 'Common string',
    defaultMessage: 'price',
  },
  commonDelete: {
    id: 'core.common.delete',
    description: 'Common string',
    defaultMessage: 'delete',
  },
  commonAuthenticating: {
    id: 'core.common.authenticating',
    description: 'Common string',
    defaultMessage: 'authenticating',
  },
  commonError: {
    id: 'core.common.error',
    description: 'Common string',
    defaultMessage: 'error',
  },
  commonReset: {
    id: 'core.common.reset',
    description: 'Common string',
    defaultMessage: 'reset',
  },
  commonUndo: {
    id: 'core.common.undo',
    description: 'Common string',
    defaultMessage: 'undo',
  },
  commonProceed: {
    id: 'core.common.proceed',
    description: 'Common string',
    defaultMessage: 'proceed',
  },
  commonYes: {
    id: 'core.common.yes',
    description: 'Common string',
    defaultMessage: 'yes',
  },
  commonNo: {
    id: 'core.common.no',
    description: 'Common string',
    defaultMessage: 'no',
  },
  commonName: {
    id: 'core.common.name',
    description: 'Common string',
    defaultMessage: 'name',
  },
  commonHelp: {
    id: 'core.common.help',
    description: 'Common string',
    defaultMessage: 'help',
  },
  commonBack: {
    id: 'core.common.back',
    description: 'Common string',
    defaultMessage: 'back',
  },
  commonSearch: {
    id: 'core.common.search',
    description: 'Common string',
    defaultMessage: 'search',
  },
  commonAcknowledgements: {
    id: 'core.common.acknowledgements',
    description: 'Common string',
    defaultMessage: 'acknowledgements',
  },
  commonRefresh: {
    id: 'core.common.refresh',
    description: 'Common string',
    defaultMessage: 'refresh',
  },
  commonAuthorizationCancelled: {
    id: 'core.common.authorizationCancelled',
    description: 'Common string',
    defaultMessage: 'authorization cancelled',
  },
  commonNoResults: {
    id: 'core.common.noResults',
    description: 'Common string',
    defaultMessage: 'no results',
  },
  commonSendFeedback: {
    id: 'core.common.sendFeedback',
    description: 'Common string',
    defaultMessage: 'send feedback',
  },
  commonfieldIsRequired: {
    id: 'core.common.fieldIsRequired',
    description: 'Common string',
    defaultMessage: 'field is required',
  },
  commonPleaseWait: {
    id: 'core.common.pleaseWait',
    description: 'Common string',
    defaultMessage: 'please wait...',
  },
  commonAnErrorOccurred: {
    id: 'core.common.AnErrorOccurred',
    description: 'Common string',
    defaultMessage: 'An error occurred',
  },
  disclaimerTitle: {
    id: 'core.disclaimer.title',
    description: 'Disclaimer title',
    defaultMessage: 'Disclaimer',
  },
  commonFailedToLoad: {
    id: 'core.common.failedToLoad',
    description: 'Failed to load',
    defaultMessage: "Failed to load information. Please make sure you're connected to the internet and try again.\nContact support if the problem continues.",
  },
  disclaimerTextTop: {
    id: 'core.disclaimer.textTop',
    description: 'Disclaimer text',
    defaultMessage:
      'The driver times shown in the Volvo Connect App shall be seen as a complement to the data presented in the tachograph or in the vehicles instrument cluster. The latter is more safe to use while driving and delivers frequently updated data.',
  },
  disclaimerTextMiddle: {
    id: 'core.disclaimer.textMiddle',
    description: 'Disclaimer text',
    defaultMessage:
      'The driver times shown in the Volvo Connect App can preferably be used to keep track of your driving and resting times when you are away from the vehicle.',
  },
  disclaimerTextBottom: {
    id: 'core.disclaimer.textBottom',
    description: 'Disclaimer text',
    defaultMessage:
      'The reliability of the times depend on the connectivity between the vehicle and Volvo Connect and how recent the driver card data has been downloaded.',
  },
  commonStartDemo: {
    id: 'core.common.startDemo',
    description: 'Common string',
    defaultMessage: 'Start demo',
  },
  commonMilesPerHour: {
    id: 'core.common.milesPerHour',
    description: 'Common string',
    defaultMessage: 'mph',
  },
  commonMilesPerGallon: {
    id: 'core.common.milesPerGallon',
    description: 'Common string',
    defaultMessage: 'mpg',
  },
  commonKilometersPerLiter: {
    id: 'core.common.kilometersPerLiter',
    description: 'Common string',
    defaultMessage: 'km/l',
  },
  commonKilometersPerUsGallon: {
    id: 'core.common.kilometersPerUsGallon',
    description: 'Common string',
    defaultMessage: 'km/US gallon',
  },
  commonMiles: {
    id: 'core.common.miles',
    description: 'Common string',
    defaultMessage: 'm',
  },
  commonMilesPerKilogram: {
    id: 'core.common.milesPerKilogram',
    description: 'Common string',
    defaultMessage: 'm/kg',
  },
  commonKilometersPerKilogram: {
    id: 'core.common.kilometersPerKilogram',
    description: 'Common string',
    defaultMessage: 'km/kg',
  },
  commonNoInternetConnection: {
    id: 'core.common.noInternetConnection',
    description: 'Common string',
    defaultMessage: 'No internet connection',
  },
  commonCheckConnection: {
    id: 'core.common.checkConnection',
    description: 'Common string',
    defaultMessage: 'Please check your connection or try again later',
  },
  newPassword: {
    id: 'admin-cup.my-account-sections.confirm-password.newPassword',
    description: 'New password',
    defaultMessage: 'New password',
  },
  oldPassword: {
    id: 'admin-cup.my-account-sections.change-password.oldPassword',
    description: 'Old passwprd',
    defaultMessage: 'Old password',
  },
  newPasswordSameAsCurrent: {
    id: 'admin-cup.my-account-sections.confirm-password.newPasswordSameAsOld',
    description: 'Must not be identical to your old password',
    defaultMessage: 'Must not be identical to your old password',
  },
  confirmPassword: {
    id: 'admin-cup.my-account-sections.confirm-password.confirmPassword',
    description: 'Confirm password',
    defaultMessage: 'Confirm password',
  },
  errorPasswordsMustMatch: {
    id: 'admin-cup.my-account-sections.confirm-password.errorPasswordsMustMatch',
    description: 'Passwords must match',
    defaultMessage: 'Passwords must match',
  },
  oldPasswordEmpty: {
    id: 'admin-cup.my-account-sections.confirm-password.oldPasswordEmpty',
    description: 'This is a mandatory field',
    defaultMessage: 'This is a mandatory field',
  },
  not_found: {
    id: 'core.components.noRouteMatch.errorMessage',
    description: 'A short message to display information about that the current page does not exist',
    defaultMessage: 'Page not found',
  },
  not_found_info: {
    id: 'core.components.noRouteMatch.info',
    description: 'The description of how to move on from the 404 page',
    defaultMessage:
      'The page you were looking for appears to have been moved or deleted, or does no longer exist. \n Use the navigation above or the link below to start over.',
  },
  unauthorized: {
    id: 'core.components.noRouteMatch.unauthorized.errorMessage',
    description: 'A short message to display information about that the current page cannot be accessed',
    defaultMessage: 'Unauthorized',
  },
  unauthorized_info: {
    id: 'core.components.noRouteMatch.unauthorized.info',
    description: 'The description of how to move on from the 404 page',
    defaultMessage: 'You do not have the necessary permissions to access this page. \n Use the navigation above or the link below to start over.',
  },
  unknown: {
    id: 'core.components.noRouteMatch.unknown.errorMessage',
    description: 'A short message to display information about that the current page cannot be accessed',
    defaultMessage: 'Unknown',
  },
  unknown_info: {
    id: 'core.components.noRouteMatch.unknown.info',
    description: 'The description of how to move on from the 404 page',
    defaultMessage: 'The page failed to open due to unknown causes. \n Use the navigation above or the link below to start over.',
  },
  back_button: {
    id: 'core.components.noRouteMatch.back.button',
    description: 'A link button back to the start page',
    defaultMessage: 'Start page',
  },
  km: {
    id: 'core.utils.distance.km',
    description: 'Distance unit for km',
    defaultMessage: 'km',
  },
  mi: {
    id: 'core.utils.distance.mi',
    description: 'Distance unit for miles',
    defaultMessage: 'mi',
  },
  hour_short: {
    id: 'core.utils.time.hour_short',
    description: 'Time unit for hour, shorthand',
    defaultMessage: 'h',
  },
  titleErrorMessage: {
    id: 'core.containers.reversegeocoding.titleErrorMessage',
    defaultMessage: 'Could not load address',
  },
  bodyErrorMessage: {
    id: 'core.containers.reversegeocoding.bodyErrorMessage',
    defaultMessage: 'Could not load address from server, please try again later or contact support.',
  },
  loadingMessage: {
    id: 'core.containers.reversegeocoding.loadingMessage',
    defaultMessage: 'Loading address',
  },
  loadingErrorMessage: {
    id: 'core.containers.reversegeocoding.loadingErrorMessage',
    defaultMessage: 'Unable to load address',
  },
  noAddressFound: {
    id: 'core.containers.reversegeocoding.noAddressFound',
    defaultMessage: 'Address unknown',
  },
  header: {
    id: 'core.components.reportableError.header',
    description: '',
    defaultMessage: 'Unexpected Error',
  },
  criticalErrorHeader: {
    id: 'core.components.reportableError.header.critical',
    description: '',
    defaultMessage: 'Unfortunately, the portal has encountered a critical error. Please try to reload the page.',
  },
  message: {
    id: 'core.components.reportableError.message',
    description: '',
    defaultMessage:
      'Unfortunately something went wrong. \n Try to reload the page, or click below to create an error report which you can use when contacting support:',
  },
  messageNonInteractive: {
    id: 'core.components.reportableError.message.nonInteractive',
    description: '',
    defaultMessage: 'If you contact support, please provide them with the following error code.',
  },
  messageBuses: {
    id: 'core.components.reportableError.message.buses',
    description: '',
    defaultMessage:
      'Unfortunately something went wrong. \n Try to reload the page, or click below to create an error report which you can use when contacting our helpdesk:',
  },
  messageBusesNonInteractive: {
    id: 'core.components.reportableError.message.buses.nonInteractive',
    description: '',
    defaultMessage: 'If you contact our helpdesk, please provide them with the following error code.',
  },
  trackingId: {
    id: 'core.components.reportableError.trackingId',
    description: '',
    defaultMessage: 'Tracking ID:',
  },
  reportError: {
    id: 'core.components.reportableError.reportError',
    description: '',
    defaultMessage: 'Create error report',
  },
  startPage: {
    id: 'core.components.errorPage.startPage',
    description: 'A button that redirects to the start page',
    defaultMessage: 'Start page',
  },
  errorReporterHeader: {
    id: 'core.containers.ErrorReporter.header',
    description: 'Header for the Error Reporter',
    defaultMessage: 'Report an issue',
  },
  errorReporterMainTitle: {
    id: 'core.containers.ErrorReporter.mainTitle',
    description: 'Main title for the Error Reporter dialog',
    defaultMessage: 'Please provide us with details regarding your issue',
  },
  errorReporterIssueTypeLabel: {
    id: 'core.containers.ErrorReporter.issueTypeLabel',
    description: 'the label for the Issue Type dropdown',
    defaultMessage: 'Issue type',
  },
  errorReporterIssueTypeDescription: {
    id: 'core.containers.ErrorReporter.issueTypeDescription',
    description: 'the description for the Issue Type dropdown',
    defaultMessage: 'Choose a description best matching your report to help us better understand your issue',
  },
  errorReporterDetailsLabel: {
    id: 'core.containers.ErrorReporter.detailsLabel',
    description: 'the label for the issue details input',
    defaultMessage: 'Details',
  },
  errorReporterDetailsPlaceholder: {
    id: 'core.containers.ErrorReporter.detailsPlaceholder',
    description: 'the placeholder text for the issue details input',
    defaultMessage: 'Please describe your issue in as much detail as possible. For example, what were you trying to do when it happened?',
  },
  errorReporterActionCancel: {
    id: 'core.containers.ErrorReporter.actionCancel',
    description: 'label for the cancel button',
    defaultMessage: 'Cancel',
  },
  errorReporterActionSubmit: {
    id: 'core.containers.ErrorReporter.actionSubmit',
    description: 'label for the submit button',
    defaultMessage: 'Submit',
  },
  errorReporterActionFinish: {
    id: 'core.containers.ErrorReporter.actionFinish',
    description: 'label for the finish button',
    defaultMessage: 'Finish',
  },
  errorReporterSubmitting: {
    id: 'core.containers.ErrorReporter.submitting',
    description: 'Text to show while submitting',
    defaultMessage: 'Submitting',
  },
  errorReporterError: {
    id: 'core.containers.ErrorReporter.error',
    description: 'Text to show if there was an error ',
    defaultMessage: 'There was an error submitting your report, please try again',
  },
  errorReporterSuccess: {
    id: 'core.containers.ErrorReporter.success',
    description: 'Text to show if the operation succeeded ',
    defaultMessage: 'Thank you for your report. Please provide the following code when speaking to support',
  },
  errorReporterCreatingCode: {
    id: 'core.containers.ErrorReporter.creatingCode',
    description: 'Text to show while generating the error code',
    defaultMessage: 'Generating error code, please wait...',
  },
  errorReporterIssueTypeDisturbance: {
    id: 'core.containers.ErrorReporter.issueTypeDisturbance',
    description: 'Label for the disturbance issue type',
    defaultMessage: 'Disturbance',
  },
  errorReporterIssueTypeOther: {
    id: 'core.containers.ErrorReporter.issueTypeOther',
    description: 'Label for the other issue type',
    defaultMessage: 'Other',
  },
  gdprNotice: {
    id: 'core.containers.ErrorReporter.gdprNotice',
    description: 'Label for the other issue type',
    defaultMessage:
      'PRIVACY NOTICE: By submitting an error report, you consent that your user and session data - including but not limited to system logs, network traffic and user information - may be used for the sole purpose of troubleshooting your reported issue. This information will not be shared with external parties and is automatically deleted within 90 days of creation. For full information see our privacy policy.',
  },
  supportIcon: {
    id: 'core.containers.ErrorReporter.support.icon',
    description: 'Label for the support icon error generator',
    defaultMessage: 'Create support package',
  },
  supportBody: {
    id: 'core.containers.ErrorReporter.support.body',
    description: 'Support package description',
    defaultMessage: 'Create a debug package for your current portal session. This can be used by support to help troubleshoot problems you have reported',
  },
  supportLoading: {
    id: 'core.containers.ErrorReporter.support.loading',
    description: 'Support package description when loading',
    defaultMessage: 'Working, please wait',
  },
  supportDone: {
    id: 'core.containers.ErrorReporter.support.done',
    description: 'Support package description when done',
    defaultMessage: 'Package creation complete, please provide the following code to support.',
  },
  supportError: {
    id: 'core.containers.ErrorReporter.support.error',
    description: 'Support package description when error',
    defaultMessage: 'Package creation failed, please try again in a moment.',
  },
  adminDisplayName: {
    id: 'core.adminModule.displayName',
    description: 'Admin module name to be displayed',
    defaultMessage: 'Admin',
  },
  positioningDisplayName: {
    id: 'core.positioningModule.displayName',
    description: 'Positioning module name to be displayed',
    defaultMessage: 'Map',
  },
  reportsDisplayName: {
    id: 'core.reportsModule.displayName',
    description: 'Reports module name to be displayed',
    defaultMessage: 'Reports',
  },
  assetsDisplayName: {
    id: 'core.assetsModule.displayName',
    description: 'Assets module name to be displayed',
    defaultMessage: 'Assets',
  },
  alertsDisplayName: {
    id: 'core.alertsModule.displayName',
    description: 'Alerts module name to be displayed',
    defaultMessage: 'Alerts',
  },
  boilerplateDisplayName: {
    id: 'core.boilerplateModule.displayName',
    description: 'Boilerplate module name to be displayed. This is only for testing. No translation is needed.',
    defaultMessage: 'Boilerplate',
  },
  driverTimesDisplayName: {
    id: 'core.driverTimesModule.displayName',
    description: 'Driver Times module name to be displayed',
    defaultMessage: 'Driver Times',
  },
  supportDisplayName: {
    id: 'core.driverTimesModule.displayName',
    description: 'Driver Times module name to be displayed',
    defaultMessage: 'Driver Times',
  },
  calendarDisplayName: {
    id: 'core.calendarModule.displayName',
    description: 'Calendar module name to be displayed',
    defaultMessage: 'Calendar',
  },
  workShopHistoryDisplayName: {
    id: 'core.workShopHistory.displayName',
    description: 'Workshop History module name to be displayed',
    defaultMessage: 'Workshop History',
  },
  volvoconnect: {
    id: 'core.common.volvoconnectTitle',
    description: "The application's title",
    defaultMessage: 'Volvo Connect',
  },
  volvobusconnect: {
    id: 'core.common.volvobusconnectTitle',
    description: "The application's title",
    defaultMessage: 'Volvo Connect',
  },
  udconnect: {
    id: 'core.common.udconnectTitle',
    description: "The application's title",
    defaultMessage: 'My UD Fleet',
  },
  optifleet: {
    id: 'core.common.optifleetTitle',
    description: "The application's title",
    defaultMessage: 'Optifleet',
  },
  caretrack: {
    id: 'core.common.caretrackTitle',
    description: "The application's title",
    defaultMessage: 'CareTrack',
  },
  marketplace: {
    id: 'core.common.marketplaceTitle',
    description: "The application's title",
    defaultMessage: 'Marketplace',
  },
  supporttool: {
    id: 'core.common.supporttoolTitle',
    description: "The application's title",
    defaultMessage: 'Support Tool',
  },
  snac: {
    id: 'core.common.snacTitle',
    description: "The application's title",
    defaultMessage: 'Support Tool',
  },
  elegantconnect: {
    id: 'core.common.elegantconnectTitle',
    description: "The application's title",
    defaultMessage: 'ElegantConnect',
  },
  snacdealeraccess: {
    id: 'core.common.snacdealeraccessTitle',
    description: "The application's title",
    defaultMessage: 'Dealer Assets Overview',
  },
  renaultTrucksCustomerPortal: {
    id: 'core.common.renault.customer.portal.title',
    description: 'The officially translated title of the Renault Trucks Customer Portal. Note that the Renault Trucks bit is not translated',
    defaultMessage: 'Customer Portal',
  },
});
