import { useState, useCallback } from 'react';
import { Body, Footer, Button, Icon, Alert, AlertTitle, Tooltip, List, ListItem, ListItemText, Switch, FormControlLabel } from '@ccsdk/kingbolt';
import useDevTools from '~/hooks/useDevTools';
import ThemeSelector from '~/components/DevOverlay/DevOverlayContent/ThemeView/ThemeSelector';
import ThemeViewerLink from '~/components/DevOverlay/DevOverlayContent/ThemeView/ThemeViewerLink';

const ThemeView = () => {
  const { devToolsTheme, setDevToolsTheme, setDevToolsThemeMode, devToolsThemeMode } = useDevTools();
  const [theme, setTheme] = useState(devToolsTheme || 'volvoconnect');
  const [isDarkMode, setIsDarkMode] = useState(devToolsThemeMode === 'dark');

  const applyTheme = useCallback(() => {
    setDevToolsThemeMode(isDarkMode);
    setDevToolsTheme(theme);
    window.location.reload();
  }, [setDevToolsTheme, theme, isDarkMode]);

  const clearTheme = useCallback(() => {
    setDevToolsTheme(null);
    setDevToolsThemeMode(null);
    window.location.reload();
  }, [setDevToolsTheme]);

  return (
    <>
      <Alert severity="info" sx={{ borderRadius: 0 }}>
        <AlertTitle>Themes</AlertTitle>
        <List disablePadding>
          <ListItem disableGutters disablePadding>
            <ListItemText
              primary="Switching a theme does NOT change the brand."
              secondary="You may experience issues with theme/brand compatibility if your tool has internal logic based on brand."
            />
          </ListItem>
          <ListItem disableGutters disablePadding>
            <ListItemText primary="The themes are applied in you local storage." secondary="Changes you make will not affect the experience for other users." />
          </ListItem>
        </List>
      </Alert>
      <Body>
        <ThemeSelector label="All available themes" onThemeChange={setTheme} selectedThemeId={theme} />
        <FormControlLabel
          value="darkmode"
          control={<Switch data-test-id="dark-mode-switch" checked={isDarkMode} onChange={(e) => setIsDarkMode(e.target.checked)} />}
          label="Dark mode"
          labelPlacement="start"
        />
        <ThemeViewerLink />
      </Body>
      <Footer>
        <Tooltip title="Will remove your locally selected theme and return the portal to it's original state.">
          <Button icon={<Icon type="trash" />} onClick={clearTheme}>
            Clear
          </Button>
        </Tooltip>
        <Tooltip title="Will apply the selected theme and reload the portal.">
          <Button icon={<Icon type="refresh" />} onClick={applyTheme} variant="contained">
            Apply
          </Button>
        </Tooltip>
      </Footer>
    </>
  );
};

export default ThemeView;
