import { isLoginMode } from '@customer-connect/utils';
import { Workbox } from 'workbox-window';

export const initServiceWorker = () => {
  window.ccsdkGlobals.serviceWorker = new Workbox('/service-worker-new.js');
  window.ccsdkGlobals.serviceWorker.register().then(() => {
    navigator.serviceWorker.addEventListener('message', async (event) => {
      if (event.data.meta === 'workbox-broadcast-update') {
        const { cacheName, updatedUrl } = event.data.payload;
        console.debug('App Shell', 'ServiceWorker', 'Cache was updated', cacheName, updatedUrl);
      }

      if (event.data.meta === 'cc-intercepted-request') {
        window.ccsdkGlobals.errorReport.network.push({
          timestamp: new Date().toISOString(),
          ...event.data.payload,
        });
      }
    });

    // Load dev tools for SW
    const dataScramblerEnabled = window.localStorage.getItem('devToolsDataScramblerEnabled') === 'true';
    if (!isLoginMode()) {
      console.debug('App Shell', 'ServiceWorker', 'Sending SET_DEV_OPTION', 'dataScrambler', Boolean(dataScramblerEnabled));
    }
  });
};
