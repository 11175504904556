import { Suspense } from 'react';
import ErrorBoundary from '@customer-connect/components-legacy/dist/components/ErrorBoundary';
import { string } from 'prop-types';
import { useFederatedModules } from '@customer-connect/hooks-web';

const byWeightDesc = ({ weight: weightA = 0 }, { weight: weightB = 0 }) => weightB - weightA;

const HeaderNavigation = ({ drawerId }) => {
  const { isLoading, modules = [] } = useFederatedModules('headerNode');

  if (isLoading) {
    return null;
  }

  return modules.sort(byWeightDesc).map(({ component: Component, id }) => (
    <Suspense key={id} fallback={null}>
      <ErrorBoundary.Empty>
        <Component material portalReference={drawerId} />
      </ErrorBoundary.Empty>
    </Suspense>
  ));
};

HeaderNavigation.propTypes = {
  drawerId: string,
};

export default HeaderNavigation;
