import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setVisible } from '@customer-connect/redux-actions';

const PageStatusChecker = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.debug('App Shell', 'Visibility', 'Setting initial visibility', !document.hidden);
    dispatch(setVisible({ value: !document.hidden }));

    window.addEventListener('visibilitychange', () => {
      dispatch(setVisible({ value: !document.hidden }));
    });
  }, []);

  return null;
};

export default PageStatusChecker;
