import { useEffect, useState, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Dialog, DialogContent, Header, HeaderTitle, Body, IconButton, Icon, Box } from '@ccsdk/kingbolt';
import { useIsLoginMode } from '@customer-connect/hooks-web';
import useDevTools from '~/hooks/useDevTools';
import { DialogPaper } from '~/components/DevOverlay/styles';
import DevOverlayContent from '~/components/DevOverlay/DevOverlayContent';
import DevOverlayMenu from '~/components/DevOverlay/DevOverlayMenu';
import { ACTIVATION_KEYS, views } from './constants';

const { GENERAL } = views;

const DevOverlay = () => {
  const isLoginMode = useIsLoginMode();
  const [enabled, setEnabled] = useState(false);
  const [view, setView] = useState(GENERAL);
  const { scramblerPermission, dataScrambleEnabled, disableDataScramble } = useDevTools();
  const onClose = useCallback(() => {
    setEnabled(false);
  }, []);

  useEffect(() => {
    console.debug('App Shell', 'Setting up dev overlay');
    if (!isLoginMode && !scramblerPermission && dataScrambleEnabled) {
      console.debug('App Shell', 'Dev Overlay', 'User does not have scrambler permission');
      disableDataScramble();
    }
  }, [scramblerPermission, disableDataScramble]);

  useHotkeys(ACTIVATION_KEYS, () => {
    setEnabled(true);
    return false;
  });

  useEffect(() => {
    const activateOverlay = () => setEnabled(true);

    // assign the function to the window
    window.activateDevOverlay = activateOverlay;

    return () => {
      // remove the function from the window
      delete window.activateDevOverlay;
    };
  });

  return (
    <Dialog PaperComponent={DialogPaper} onClose={onClose} open={enabled}>
      <DialogContent>
        <Header>
          <HeaderTitle>Dev tools</HeaderTitle>
          <IconButton onClick={onClose}>
            <Icon type="close" />
          </IconButton>
        </Header>
        <Body disablePadding>
          <Box display="flex" flexGrow={1}>
            <DevOverlayMenu setView={setView} view={view} />
            <DevOverlayContent view={view} />
          </Box>
        </Body>
      </DialogContent>
    </Dialog>
  );
};

export default DevOverlay;
