import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useAPI, useBrand, useCloudFrontMeta, useCurrentSession, useIsLoggedIn, useUserSettings } from '@customer-connect/hooks-web';
import { Alert, Icon, IconButton, Link as LinkButton } from '@ccsdk/kingbolt';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { severities } from '~/components/GlobalNotifications/constants';
import { closeNotification } from './actions';
import { useGetIncidentsQuery } from './services/incidentAPI';
import { useAnalytics } from './utils/useAnalytics';
import messages from './messages';

dayjs.extend(LocalizedFormat);

const { NOTICE, WARNING, ERROR } = severities;

export const getAlertSeverity = (severity) => {
  switch (severity) {
    case NOTICE:
      return 'info';
    case WARNING:
      return 'warning';
    case ERROR:
      return 'error';
    default:
      return 'info';
  }
};

const quebecTemporaryAnnouncement =
  window.location.hostname !== 'localhost'
    ? {
        notificationPlatformId: 'quebec_temporary',
        severity: 'info',
      }
    : null;

const renderNotificationText = (notification) => {
  const { maintanenceTimeStart, maintanenceTimeEnd, notificationPlatformId, message } = notification;

  if (notificationPlatformId === 'quebec_temporary') {
    return (
      <>
        <span>
          We apologize that a French translation is not currently available. We are working to implement a translation as soon as possible. By continuing with
          use of the platform, you agree to its use in English until the French translation is available.
        </span>
        <br />
        <span>
          {`Nous sommes désolés qu'une traduction française ne soit pas disponible actuellement. Nous travaillons à la mise en place d'une traduction dès que possible. En poursuivant votre utilisation de la plateforme, vous acceptez qu'elle soit utilisée en anglais jusqu'à ce que la traduction française soit disponible.`}
        </span>
      </>
    );
  }
  const startTime = dayjs(maintanenceTimeStart).format('L LT');
  const endTime = dayjs(maintanenceTimeEnd).format('L LT');

  return message?.text.replace('[timeStart]', startTime).replace('[timeEnd]', endTime);
};

const GlobalNotifications = () => {
  const baseUrl = useAPI('incident');
  const [closedNotifications, setClosedNotifications] = useState(JSON.parse(window.localStorage.getItem('closed_notifications')) || []);
  const [hideEndpointBanner, setHideEndpointBanner] = useState(false);
  const { formatMessage } = useIntl();
  const { locale } = useUserSettings();
  const brand = useBrand();
  const isLoggedIn = useIsLoggedIn();
  const dispatch = useDispatch();
  const { closeIncidentBannerAnalytics, readmoreIncidentBannerAnalytics, bannerHasBeenLoadedAnalytics } = useAnalytics();

  const { data, isError } = useGetIncidentsQuery({ isLoggedIn, baseUrl, brand, locale });

  const unsortedDisturbances = data?.incidentSummaries.filter((removeEmpty) => removeEmpty) ?? [];

  const disturbances = unsortedDisturbances.sort((a, b) => {
    if (a.incidentType === b.incidentType) {
      return a.incidentState - b.incidentState;
    } else {
      return a.incidentType - b.incidentType;
    }
  });

  const { region } = useCurrentSession();

  const { country } = useCloudFrontMeta();

  const blameCanada = region === 'na' && (country === 'CA' || country === 'CAN');

  const notification = [...disturbances, blameCanada ? quebecTemporaryAnnouncement : null]?.filter((not) => {
    const notId = not?.notificationPlatformId || not?.incidentId;
    return !closedNotifications.includes(notId);
  })[0];

  const notificationId = notification?.notificationPlatformId || notification?.incidentId;

  useEffect(() => {
    if (notification) {
      bannerHasBeenLoadedAnalytics(isLoggedIn, notificationId);
    }
  }, [notification]);

  const handleCloseNotification = () => {
    setClosedNotifications([...closedNotifications, notificationId]);
    dispatch(closeNotification({ notificationId }));
    closeIncidentBannerAnalytics(isLoggedIn, notificationId);
  };

  const closeBannerCantReachEndpoint = () => {
    setHideEndpointBanner(true);
  };

  if (isError && !hideEndpointBanner) {
    return (
      <Alert
        action={
          <IconButton onClick={closeBannerCantReachEndpoint}>
            <Icon type="close" />
          </IconButton>
        }
        disableRadius
        severity="info"
      >
        <span>{formatMessage(messages.bannerMessage)}</span>
      </Alert>
    );
  }
  return (
    notification && (
      <Alert
        action={
          <IconButton onClick={handleCloseNotification}>
            <Icon type="close" />
          </IconButton>
        }
        disableRadius
        severity={getAlertSeverity(notification.severity)}
      >
        <span data-cy="notificationtext" data-test-id="notificationtext">
          {renderNotificationText(notification)}
        </span>

        <LinkButton
          component={Link}
          data-cy="readmore"
          data-test-id="readmore"
          onClick={() => readmoreIncidentBannerAnalytics(isLoggedIn, notificationId)}
          sx={{ mx: '4px' }}
          to="/support/systemstatus"
        >
          {formatMessage(messages.readMoreButton)}
        </LinkButton>
      </Alert>
    )
  );
};

export default GlobalNotifications;
