import { useEffect, useState, useCallback } from 'react';
import { node } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dialog, DialogContent, Header, HeaderTitle, Body, Footer, TextField, Button, Box } from '@ccsdk/kingbolt';
import { selectEmergencyKeys } from '@customer-connect/redux-selectors';
import { setEmergencyKey } from '@customer-connect/redux-actions';
import { selectQueries } from '@customer-connect/redux-selectors';
import useRootPath from '~/hooks/useRoutePath';
import messages from './messages';

const EmergencyMode = ({ children }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [input, setInput] = useState(null);
  const rootPath = useRootPath();

  const queries = useSelector(selectQueries);
  const emergencyKeys = useSelector(selectEmergencyKeys);

  const currentKey = emergencyKeys[rootPath];
  const isEmergencyMode = queries.emergencyMode;

  useEffect(() => {
    if (isEmergencyMode) {
      window?.removeLottie?.(1000);
    }
  }, [isEmergencyMode]);

  useEffect(() => {
    console.debug('App Shell', 'Mounting EmergencyMode');
    return /* istanbul ignore next */ () => console.debug('App Shell', 'Unmounting EmergencyMode');
  }, []);

  const onSave = useCallback(() => dispatch(setEmergencyKey({ tool: rootPath, key: input })), [input]);

  const onTextUpdated = useCallback(({ target }) => setInput(target.value), []);

  if (!isEmergencyMode || currentKey) {
    return children;
  }

  return (
    <Dialog aria-labelledby="emergency-mode-dialog" open>
      <DialogContent>
        <Header>
          <HeaderTitle>{formatMessage(messages.header)}</HeaderTitle>
        </Header>
        <Body>
          <Box>{formatMessage(messages.info)}</Box>
          <TextField fullWidth label={formatMessage(messages.input)} onChange={onTextUpdated} size="small" />
        </Body>
        <Footer>
          <Button disabled={input?.length === 0} onClick={onSave}>
            {formatMessage(messages.save)}
          </Button>
        </Footer>
      </DialogContent>
    </Dialog>
  );
};

EmergencyMode.propTypes = {
  children: node,
};

export default EmergencyMode;
