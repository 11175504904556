import { getAPI } from '@customer-connect/utils';

export const fetchUIDRequest = async (accessToken) => {
  const api = getAPI('portals');
  const url = `${api}/adobeuid`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      console.warn('App Shell', 'Auth', 'Error when fetching UIDs, returning default object', error);
      return {};
    });
};
