import { useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dialog, Header, HeaderTitle, IconButton, Icon, Body, Wizard, WizardHeader, Footer, DialogContent } from '@ccsdk/kingbolt';
import { hideErrorReporterAction } from '@customer-connect/redux-actions';
import { useErrorReporter } from './hooks/useErrorReporter';
import formReducer, { initialFormState, RESET_FORM } from './reducer';
import formSteps from './formSteps';
import messages from './messages';
import { DialogBody } from './styles';
import Buttons from './components/Buttons/index';
import ErrorMessage from './components/ErrorMessage';
import FormViews from './components/FormViews/index';

export const ErrorReporter = () => {
  const { formatMessage } = useIntl();
  const reduxDispatch = useDispatch();

  const [viewIndex, setViewIndex] = useState(0);

  const { reset, submit, error } = useErrorReporter();

  const [state, dispatch] = useReducer(formReducer, initialFormState);

  const updateFormData = (type, data) => dispatch({ type, data });

  const onSubmit = () => {
    submit(state);
  };

  const onClose = () => {
    reduxDispatch(hideErrorReporterAction());
    updateFormData(RESET_FORM);
    reset();
  };

  // FormSteps
  const { step, view, title, description, success, valid, numberOfSteps } = formSteps(viewIndex, state);

  return (
    <Dialog PaperComponent={DialogBody} data-cy-dialog="error-reporter" open>
      <DialogContent>
        <Header>
          <HeaderTitle data-cy-header-title="report-issue">{formatMessage(messages.dialogHeaderTitle)}</HeaderTitle>
          <IconButton onClick={onClose}>
            <Icon data-cy-close-icon type="close" />
          </IconButton>
        </Header>
        <Body disablePadding>
          {error ? (
            <ErrorMessage />
          ) : (
            <Wizard>
              <WizardHeader
                data-cy-wizard-header="error-reporter"
                description={description || ''}
                step={step}
                success={success}
                title={title}
                totalSteps={numberOfSteps - 1}
              />
              <Body disablePadding>
                <FormViews state={state} updateFormData={(type, data) => updateFormData(type, data)} viewIndex={viewIndex} />
              </Body>
            </Wizard>
          )}
        </Body>
        <Footer>
          <Buttons activeView={view} close={onClose} handleClick={(nextView) => setViewIndex(viewIndex + nextView)} submit={onSubmit} valid={valid} />
        </Footer>
      </DialogContent>
    </Dialog>
  );
};

ErrorReporter.displayName = 'ErrorReporter';

export default ErrorReporter;
