import { useStatisticsLogger } from '@customer-connect/components-legacy/dist/containers/StatisticsLogger/hooks';
import { useConfig, useCloudFrontMeta } from '@customer-connect/hooks-web';

if (!window.ccsdkGlobals) {
  window.ccsdkGlobals = {};
}

if (!window.ccsdkGlobals.timings) {
  window.ccsdkGlobals.timings = {};
}

if (!window.ccsdkGlobals.logBatch) {
  window.ccsdkGlobals.logBatch = [];
}

export const useAnalyticsTimings = () => {
  const brand = useConfig('brand');
  const isTestBot = useConfig('isTestBot');
  const { country } = useCloudFrontMeta();
  const { recordStatistic } = useStatisticsLogger();

  const setItem = ({ key, value, context = 'local' }) => {
    if (context === 'local') {
      window.ccsdkGlobals.timings[key] = value;
    }

    if (context === 'session') {
      window.sessionStorage.setItem(key, value);
    }
  };

  const getItem = ({ key, context = 'local' }) => {
    if (context === 'local') {
      return window.ccsdkGlobals.timings[key];
    }

    if (context === 'session') {
      return window.sessionStorage.getItem(key);
    }

    return undefined;
  };

  const deleteItem = ({ key, context }) => {
    if (context === 'local') {
      delete window.ccsdkGlobals.timings[key];
    }

    if (context === 'session') {
      window.sessionStorage.removeItem(key);
    }
  };

  const logTiming = ({ timing, key }) => {
    recordStatistic({
      url: `timings_${key}`,
      country,
      brand,
      type: 'meta',
      responseTime: timing,
    });
  };

  const startTimer = ({ key, context = 'local' }) => {
    if (!isTestBot) {
      const currentTime = new Date().getTime();
      setItem({ key, value: currentTime, context });
    }
  };

  const stopTimer = async ({ key, statisticsKey, context = 'local', keepItem = false }) => {
    if (isTestBot) {
      return Promise.resolve(true);
    }

    const startTime = getItem({ key, context });

    if (!startTime) {
      // console.warn('Core', 'Analytics', 'No start time associated with key', key);
      return Promise.resolve(true);
    }

    const currentTime = new Date().getTime();

    const totalTime = currentTime - startTime;

    if (!keepItem) {
      deleteItem({ key, context });
    }

    return logTiming({ timing: totalTime, key: statisticsKey || key });
  };

  return { startTimer, stopTimer };
};
