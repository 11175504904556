import { useCallback } from 'react';
import { useConfig } from '@customer-connect/hooks-web';
import { useNavigate } from 'react-router-dom';
import { PortalHeader } from '@ccsdk/kingbolt/_internal';
import { HOME_URL as DEFAULT_HOME_URL } from '~/components/Layout/consts';
import HeaderNavigation from '~/components/Layout/Header/HeaderNavigation';

const Header = () => {
  const navigate = useNavigate();
  const homeURLFromConfig = useConfig('homeURL');
  const noAuth = useConfig('noAuth');

  const onBrandLogoClick = useCallback(() => {
    if (noAuth) {
      window.location.assign('/');
    } else {
      navigate(homeURLFromConfig || DEFAULT_HOME_URL);
    }
  }, [homeURLFromConfig, noAuth]);

  return (
    <PortalHeader onLogoClick={onBrandLogoClick}>
      <HeaderNavigation drawerId="portal-drawer-container" />
    </PortalHeader>
  );
};

export default Header;
