import { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { bool, func } from 'prop-types';
import config from '@customer-connect/config-legacy';

import { injectReducers } from '@customer-connect/utils';
import { injectSagas } from '@customer-connect/utils';
import reducer, { STATE_NAME, approveCookie, loadCookieAccept, changeCookieConsent, cookieConsentSagas as saga, getApproved, getLoading } from './duck';
import CookieToaster from './components/CookieToaster';

export const CookieConsent = (props) => {
  useEffect(() => {
    props.loadCookieAccept();
  }, []);

  if (!config.get('isLoginApp') && !props.approved && !props.loading) {
    return <CookieToaster approveCookie={props.approveCookie} />;
  }

  return null;
};

CookieConsent.propTypes = {
  approveCookie: func,
  approved: bool,
  loadCookieAccept: func,
  loading: bool,
};

const mapDispatchToProps = {
  approveCookie,
  loadCookieAccept,
  setCookieConsent: changeCookieConsent,
};

const mapStateToProps = createStructuredSelector({
  approved: getApproved(),
  loading: getLoading(),
});

const withReducer = injectReducers({ key: [STATE_NAME], reducer });
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSagas({ key: 'cookieConsentSagas', saga });

export default compose(withReducer, withSaga, withConnect)(CookieConsent);
