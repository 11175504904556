import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadDynamicModuleByPath } from '@customer-connect/redux-actions';
import useRootPath from '~/hooks/useRoutePath';
import { selectFederatedTools } from '~/components/Routing/selectors';
import { compareModules } from '../utils';

const DynamicModuleLoader = () => {
  const rootPath = useRootPath();
  const dispatch = useDispatch();
  const federatedTools = useSelector(selectFederatedTools, compareModules);

  const loadedTool = useMemo(() => federatedTools?.find((tool) => rootPath === tool?.path?.replace(/^\/+/, '')) || false, [federatedTools, rootPath]);

  useEffect(() => {
    if (rootPath && rootPath !== '/' && !loadedTool) {
      console.debug('App Shell', 'Routes', 'Dispatching path change', rootPath);
      dispatch(loadDynamicModuleByPath(rootPath));
    }
  }, [rootPath]);

  return null;
};

export default DynamicModuleLoader;
