import { node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectConfig } from '@customer-connect/redux-selectors';
import { isAccessTokenValid } from '@customer-connect/utils';
import { authorize } from '@customer-connect/redux-actions';
import { AuthorizationStatus } from '@customer-connect/redux-constants';
import { selectAuthorizationStatus, selectAuthorizationError, selectAllPermissions } from '@customer-connect/redux-selectors';

import { useNewTokenListener } from './hooks';
import AuthorizationError from '~/components/Authentication/authorizationError';
import { withProfiler } from '@sentry/react';

export const noAuthSelector = makeSelectConfig('noAuth');

const REFRESH_DIFF = 10000;

const useIsTokenValid = () => isAccessTokenValid(REFRESH_DIFF);

const Authentication = ({ children }) => {
  const dispatch = useDispatch();
  const noAuth = useSelector(noAuthSelector);
  const authStatus = useSelector(selectAuthorizationStatus);
  const authorizationError = useSelector(selectAuthorizationError);
  const permissions = useSelector(selectAllPermissions);
  const accessTokenValid = useIsTokenValid();

  useNewTokenListener();

  // If we are in no-auth mode, just move on.
  if (noAuth) {
    console.info('App Shell', 'Auth', 'no-auth mode is enabled, moving on');
    return children;
  }

  if (authStatus === AuthorizationStatus.NOT_STARTED) {
    console.debug('App Shell', 'Auth', 'not logged in, initiate token renewal');
    dispatch(authorize());
    return null;
  }

  if (authStatus === AuthorizationStatus.ERROR) {
    console.error('App Shell', 'Auth', 'Authentication error encountered', authorizationError);
    return <AuthorizationError error={authorizationError} />;
  }

  if (!accessTokenValid) {
    console.debug('App Shell', 'Auth', 'Access token is invalid, re-authorizing');
    dispatch(authorize());
    return null;
  }

  if (!permissions?.length) {
    console.debug('App Shell', 'Auth', 'Waiting for permissions');
    return null;
  }

  return children;
};

Authentication.propTypes = {
  children: node,
};

export default withProfiler(Authentication, { name: 'Authentication' });
