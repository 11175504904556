import { fetchDataSaga } from '@customer-connect/utils';
import { dynamicModulesSaga } from '@customer-connect/redux-saga';
import { pushSaga, wnsSaga } from '@customer-connect/push-legacy';
import authSaga from '~/components/Authentication/sagas';
import noAuthSaga from '~/noAuthSaga';

export default function runSagas(store, options = {}) {
  const { runSaga } = store;
  const { noAuth, noAuthLogin } = options;

  runSaga(dynamicModulesSaga);

  if (noAuth) {
    runSaga(noAuthSaga);
    // Allow conditional login in noAuth mode
    if (noAuthLogin) {
      runSaga(authSaga);
    }
  } else {
    runSaga(authSaga);
  }

  runSaga(fetchDataSaga);

  runSaga(pushSaga);

  runSaga(wnsSaga);
}
