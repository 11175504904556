import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@ccsdk/kingbolt';
import { isLocalhost } from '@customer-connect/utils';
import useDevTools from '~/hooks/useDevTools';
import { LinkContainer } from './styles';

const ThemeViewerLink = () => {
  const localhost = isLocalhost();
  const { themeSwitcherPermission } = useDevTools();

  if (localhost || !themeSwitcherPermission) {
    return null;
  }

  return (
    <LinkContainer>
      If you want to view / compare a collection of kingbolt components for different themes, you can open the{' '}
      <Link component={RouterLink} target="_blank" to="/theme">
        theme viewer
      </Link>
    </LinkContainer>
  );
};

export default ThemeViewerLink;
