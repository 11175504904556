import { storageKeys } from '~/hooks/useDevTools/constants';

const { THEME, THEME_MODE } = storageKeys;

export const resolveTheme = (theme) => {
  const devTheme = localStorage?.getItem(THEME);
  if (devTheme) {
    return devTheme;
  }

  return theme;
};

export const resolveThemeMode = (mode = 'light') => {
  const devMode = localStorage?.getItem(THEME_MODE);
  if (devMode) {
    return devMode;
  }

  return mode;
};
